/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import card1 from "../../../Images/card1.svg";
import section4White from "../../../Images/section 4 white card.svg";
import section4Black from "../../../Images/section 4 black card.svg";
import barcode from "../../../Images/barcode.PNG";
import wifi from "../../../Images/wifi.PNG";
import circleLogo from "../../../Images/circle-logo-removebg-preview.png";
import { Link } from "react-router-dom";
import { CardDetailsProvider } from "../../../Context/CardDetailsContext";
import request from "../../../axios";
import AWS from "aws-sdk";
import CustomizeLandingPage from "./CustomizeLandingPage";
import { toast } from "react-toastify";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const rootS3_BUCKET = S3_BUCKET.split("/")[0];
const REGION = process.env.REACT_APP_REGION;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_accessKeyId,
  secretAccessKey: process.env.REACT_APP_secretAccessKey,
});
const myBucket = new AWS.S3({
  params: { Bucket: rootS3_BUCKET },
  region: REGION,
});

const Card = ({ user, setactiveTab = () => {} }) => {
  const [newCard, setNewCard] = useState(false);
  const [fieldSet1, setFieldSet1] = useState(false);
  const [fieldSet2, setFieldSet2] = useState(false);
  const [fieldSet3, setFieldSet3] = useState(false);
  const { cardDetails, setCardDetails } = useContext(CardDetailsProvider);
  const [cards, setCards] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [logoFileName, setLogoFileName] = useState("");
  const [profileFileName, setProfileFileName] = useState("");
  const [logoUploading, setLogoUploading] = useState(false);
  const [profilePicUploading, setProfilePicUploading] = useState(false);
  const [isSaveContinueClicked, setIsSaveContinueClicked] = useState(false);
  const [cardId, setCardId] = useState("");
  const [uniqueUserId, setUniqueUserId] = useState("");
  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [handleForm, setHandleForm] = useState([]);
  const [handleList, setHandleList] = useState([]);
  const [isCardEditMode, setIsCardEditMode] = useState(false);
  const [handleValues,setHandleValues] = useState(null);
  const [buttonsDisabled,setButtonsDisabled] = useState(false);
  const [paymentStatus,setPaymentStatus] = useState(false);
  const defaultInputs = [
    {
      id:"phone_number",
      handle_name : "phone_number",
      account_value : user?.phone_number ? user.phone_number : "",
      handle_placeholder: "Enter Phone Number",
      handle_validation: "^[0-9]{10}$",
      isWhatsapp : false,
      isRequired : true,
    },
    {
      id : 'email_id',
      handle_name : "email_id",
      account_value : user?.email ? user.email : "",
      handle_placeholder: "Enter Email ID",
      handle_validation: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
      isRequired : true,
    },
    {
      id:"about_yourself",
      handle_name : "about_yourself",
      account_value : "",
      handle_placeholder: "Tell Something About You...",
      handle_validation: "",
    },
  ]
const defaultGoogle =[{
    id:"google_review_link",
    handle_name : "google_review_link",
    account_value : "",
    handle_placeholder: "Enter Google Review Link",
    handle_validation: "^(https?://)?(www\\.)?([a-zA-Z0-9]+\\.[a-z]{2,})(/.*)?$",
    isRequired : true,
}]
const [inputs,setInputs] = useState(defaultInputs);
  useEffect(() => {
    if (user?.id) {
      request
        .get(`/get_all_cards?user_id=${user.id}`)
        .then((res) => {
          setCards(res?.data?.data?.list);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const handlOnClickNewCard = () => {
    setCardDetails({
      design: {
        card_type: "PVC",
        card_id: null,
      },
      customize: {
        name: "",
        sub_title: "",
        company_name: "",
        logo: "",
      },
      handles: [],
    });
    request
      .get(`/list_cards?material_type=PVC`)
      .then((res) => {
        if (res?.data?.data?.list) {
          setCardTypes(res?.data?.data?.list);
        }
      })
      .catch((err) => {});
    setNewCard(true);
    setFieldSet1(true);
  };

  const handleChooseCard = (cardType) => {
    if(cardType.material_type == "google_reviw_card"){
      setInputs(defaultGoogle);
    }else{
      setInputs(defaultInputs);
    }
    setCardDetails((card) => {
      return {
        ...card,
        design: {
          ...card.design,
          card_id: cardType.id,
          price: cardType.price,
        },
      };
    });
    setFieldSet2(true);
    setFieldSet1(false);
  };

  const handleSaveAndContinue = () => {
    let data = {
      ...cardDetails.customize,
      card_id: cardDetails.design.card_id,
      user_id: user.id,
    };

    if (data.name && data.company_name && data.sub_title) {
      if (!isSaveContinueClicked) {
        request
          .post("/create_card", data)
          .then((res) => {
            setFieldSet3(true);
            setFieldSet2(false);
            setIsSaveContinueClicked(true);
            setCardId(res?.data?.id || "");
            setUniqueUserId(res?.data?.user_name);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // update api called here

        data = { ...data, id: cardId };
        request
          .post("/update_card", data)
          .then((res) => {
            setFieldSet3(true);
            setFieldSet2(false);
            setIsSaveContinueClicked(true);
            setCardId(res?.data?.id || "");
            setUniqueUserId(res?.data?.user_name);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const onCardTypeChange = (e) => {
    const value = e.target.value;

    setCardDetails((card) => {
      return {
        ...card,
        design: {
          ...card.design,
          card_type: e.target.value,
        },
      };
    });

    request
      .get(`/list_cards?material_type=${value}`)
      .then((res) => {
        if (res?.data?.data?.list) {
          setCardTypes(res?.data?.data?.list);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    request
      .get(`/list_cards?material_type=${cardDetails?.design.card_type}`)
      .then((res) => {
        if (res?.data?.data?.list) {
          setCardTypes(res?.data?.data?.list);
        }
      })
      .catch((err) => {});
  }, []);

  const goToTab1 = () => {
    if (!fieldSet1) {
      setFieldSet1(true);
      setFieldSet2(false);
      setFieldSet3(false);
    }
  };
  const goToTab2 = () => {
    if (!fieldSet2 && !fieldSet1) {
      setFieldSet2(true);
      setFieldSet3(false);
    }
  };

  const handleCustomizeCardChange = (e) => {
    const { name, value } = e.target;

    setCardDetails((card) => {
      return {
        ...card,
        customize: {
          ...card.customize,
          [name]: value,
        },
      };
    });
  };

  const handleFileInput = (e, id) => {
    setButtonsDisabled(true)
    const file = e?.target?.files[0];
    if (file != undefined && file != null && file.size > 5 * 1024 * 1024) {
      toast.error("Please upload image under 5MB");
      setButtonsDisabled(false)
      return;
    }
    if (id) {
      setProfileFileName(file.name);
    } else {
      setLogoFileName(file.name);
    }
    uploadFile(file, id); // Call the uploadFile function directly
  };

  const uploadFile = (file, id) => {
    let folderName;
    let uid = cardId;
    console.log(uid);
    if (id) {
      setProfilePicUploading(true);
      folderName = 'user_profile_pics';
    } else {
      setLogoUploading(true);
      folderName = 'user_logos';
    }
    return new Promise((resolve, reject) => {
      console.log("Uploading file:", file.name);
      const fileExtension = file.name.split('.').pop();
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: rootS3_BUCKET,
        Key: `${folderName}/${user.id}.${fileExtension}`,
      };

      myBucket.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
          setLogoUploading(false);
          setProfilePicUploading(false);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data);
          // Get the URL of the uploaded file
          try {
            await callGetMediaUrl(`${user.id}.${fileExtension}`, id,folderName);
          } catch (error) {
            console.error("Error calling get_media_url API:", error);
            setLogoUploading(false);
            setProfilePicUploading(false);
            // Handle error
          }
          resolve(data);
          setButtonsDisabled(false)
        }
      });
    });
  };

  const callGetMediaUrl = async (file, id,folder) => {
    console.log(file, "file");
    try {
      const response = await request.get("/get_media_url", {
        params: { file_name: file,folder_name : folder},
      });
      let url = response?.data?.url;

      if (url) {
        url = url.split("?")[0];
      }

      console.log("URL ", url);

      if (!id) {
        setCardDetails((card) => {
          return {
            ...card,
            customize: {
              ...card.customize,
              logo: url,
            },
          };
        });
      }

      if (id) {
        setCardDetails((card) => {
          return {
            ...card,
            customize: {
              ...card.customize,
              card_profile_url: url,
            },
          };
        });
      }

      setLogoUploading(false);
      setProfilePicUploading(false);
    } catch (error) {
      console.error("Error calling get_media_url API:", error);
      setLogoUploading(false);
      setProfilePicUploading(false);

      // Handle error
    }
  };

  useEffect(() => {
    if (handleList?.length === 0) {
      request
        .get(`list_handles`)
        .then((res) => {
          let data = res?.data?.data?.list || [];
          console.log(data);
          let formData = [];
          data.forEach((handle, inddex) => {
            formData.push({
              account_id: handle.id,
              account_value: "",
              handle_name: handle.handle_name,
              handle_placeholder: handle.handle_placeholder,
              handle_validation: handle.handle_validation,
            });
          });

          if (handleForm.length === 0) {
            setHandleForm(formData);
          }
          setHandleList(res?.data?.data?.list || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const createCard = (e) => {
    // e.preventDefault();
  };

  const submitHandles = (handles) => {
    console.log(handles,'handles');
    let data = {
      handles: handles,
      card_detail_id: cardId,
    };
    let url = "/create_handles";
    if (showPreviewButton) {
      url = "/update_handles";
    }

    request
      .post(url, data)
      .then((res) => {
        console.log(res);

        if (showPreviewButton) {
          toast.success("Social Handles updated successfully");
        } else {
          toast.success(
            "Card created successfully. You can access your public page by click preview"
          );
        }
        setShowPreviewButton(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCardRowClick = (id) => {
    request(`/get_card?card_id=${id}`)
      .then((res) => {
        let data = res?.data?.data;
        console.log(data,"get_card");
        setCardDetails({
          design: {
            card_type: data.card.material_type,
            card_id: data.card_id,
          },
          customize: {
            name: data.name,
            sub_title: data.sub_title,
            company_name: data.company_name,
            logo: data.logo,
            id: data.id,
          },
        });
        setPaymentStatus(data.payment_status);

          // let allHandles = [];
          // let handleF = JSON.parse(JSON.stringify(handleForm));
          // (data?.handle_values || []).forEach((handle) => {
          //   let datas = handleF.filter(
          //     (data) => data.account_id === handle.account_id
          //   )[0];
          //   let d = { ...datas };
          //   d.account_value = handle.account_value;
          //   d.id = handle.id;
          //   allHandles = [...allHandles, d];
          //   datas = {};
          // });

        setHandleForm(data.handle_values);
        setCardId(data.id);
        setUniqueUserId(data.name_uid);
        setShowPreviewButton(true);
        setIsSaveContinueClicked(true);
        setFieldSet1(false);
        setFieldSet2(false);
        setFieldSet3(true);
        setNewCard(true);
        setIsCardEditMode(true);
        
        setInputs(data.handle_values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="col-md-9 full-sec">
      {!newCard && (
        <div style={{ height: "100%" }}>
          {cards.length === 0 ? (
            <div
              className="element-center"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={card1} className="mb-4" style={{ width: "110px" }} />
              <p>No cards found, be the architect of your first one!</p>
              <button
                className="btn tab1-btn"
                type="button"
                onClick={handlOnClickNewCard}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;{" "}
                <span>Create New Card</span>
              </button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button
                className="btn tab1-btn"
                type="button"
                onClick={handlOnClickNewCard}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;{" "}
                <span>Create New Card</span>
              </button>
            </div>
          )}
          {cards.length > 0 && (
            <div>
              <p className="mb-0 pg-hd">My Cards</p>
              <small className="small-txt">{cards.length} Cards</small>
              <div className="table-responsive page-table mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Sub title</th>
                      <th>Company</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cards.map((card) => {
                      return (
                        <tr
                          className="tble-p"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleCardRowClick(card.id);
                          }}
                        >
                          <td>
                            <ul
                              className="list-inline d-flex"
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <li className="list-inline-item">
                                <img
                                  src={card.card_profile_url}
                                  alt=""
                                  height={40}
                                  width={40}
                                />
                              </li>
                              <li className="list-inline-item">
                                <p className="mb-0">{card.name}</p>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">{card.sub_title}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">{card.company_name}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
      {newCard && (
        <div className="mycardpage2">
          <div className="top-content">
            <div className="form-box">
              <div
                className="previewButton"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {fieldSet3 && showPreviewButton && (
                  <Link to={`/${uniqueUserId}`} target="_blank" disabled>
                    <button type="button" className="btn btn-next btns">
                      Preview
                    </button>
                  </Link>
                )}

                {fieldSet3 && !showPreviewButton && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                    }}
                  >
                    <span
                      style={{ color: "red", marginBottom: "0px !important" }}
                    >
                      {" "}
                      Submit details to preview
                    </span>
                    <button
                      type="button"
                      className="btn btn-next btns"
                      disabled={!showPreviewButton ? true : false}
                    >
                      Preview
                    </button>
                  </div>
                )}
              </div>
              <form className="f1" onSubmit={(e) => e.preventDefault()}>
                <div className="f1-steps">
                  <div className="f1-progress">
                    <div
                      className="f1-progress-line"
                      data-now-value="16.66"
                      data-number-of-steps="3"
                      style={{ width: "16.66%;" }}
                    ></div>
                  </div>
                  <div
                    className={`f1-step ${fieldSet1 && "active"}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => !paymentStatus && goToTab1()}
                  >
                    <div
                      className={`f1-step-icon ${
                        newCard && !fieldSet1 && "activated"
                      }`}
                    >
                      {fieldSet1 && !fieldSet2 && !fieldSet3 && (
                        <span className="num">1</span>
                      )}
                      {!fieldSet1 && (fieldSet2 || fieldSet3) ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        ""
                      )}
                    </div>
                    <p
                      className={`timeline-txt ${
                        newCard && !fieldSet1 && "activatedText"
                      }`}
                    >
                      Select Design
                    </p>
                  </div>
                  <div
                    className={`f1-step ${fieldSet2 && "active"}`}
                    style={{ cursor: "pointer" }}
                    onClick={goToTab2}
                  >
                    <div
                      className={`f1-step-icon ${
                        newCard && !fieldSet2 && !fieldSet1 && "activated"
                      } `}
                    >
                      {fieldSet2 && !fieldSet1 && !fieldSet3 && (
                        <span className="num">2</span>
                      )}
                      {!fieldSet2 && (fieldSet1 || fieldSet3) ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        ""
                      )}
                    </div>
                    <p
                      className={`timeline-txt ${
                        newCard && !fieldSet2 && !fieldSet1 && "activatedText"
                      }`}
                    >
                      Customize Card
                    </p>
                  </div>
                  <div className={`f1-step ${fieldSet3 && "active"}`}>
                    <div className={`f1-step-icon`}>
                      {fieldSet3 && !fieldSet2 && !fieldSet1 && (
                        <span className="num">3</span>
                      )}
                      {!fieldSet3 && (fieldSet1 || fieldSet3) ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className={`timeline-txt`}>Customize Landing Page</p>
                  </div>
                </div>

                {fieldSet1 && (
                  <fieldset>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="card_type" className="pb-2">
                          Select Card Material
                        </label>
                        <br />
                        <select
                          className="dropdown selectDropdown filled"
                          placeholder="Choose card material"
                          required
                          name="card_type"
                          style={{ cursor: "pointer" }}
                          onChange={onCardTypeChange}
                          value={cardDetails.design.card_type}
                        >
                          <option value="PVC">PVC</option>
                          <option value="metal">Metal</option>
                          <option value="google_reviw_card">
                            Google review card
                          </option>
                        </select>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      {cardTypes.map((cardType) => {
                        return (
                          <div className="col-md-6">
                            {/* <img
                              src={
                                cardType.color === "white"
                                  ? section4White
                                  : section4Black
                              }
                              className="img-fluid"
                            /> */}
                            <img
                              src={
                                cardType.image_url !== null
                                  ? cardType.image_url
                                  : section4Black
                              }
                              className="img-fluid"
                            />
                            <ul className="list-inline card-confirm">
                              <li className="list-inline-item">
                                <p className="dash-rate mb-0">
                                  <del>{cardType.material_type == 'metal' ? 2999 : cardType.material_type == 'google_reviw_card' ? 699 : 999 }</del>
                                </p>
                                <p>{cardType.price}</p>
                              </li>
                              <li className="list-inline-item">
                                <button
                                  className="btn tab1-btn btn-next"
                                  type="submit"
                                  onClick={() => handleChooseCard(cardType)}
                                  // disabled={
                                  //   cardDetails?.design?.card_id &&
                                  //   cardDetails?.design?.card_id === cardType.id
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  {cardDetails?.design?.card_id &&
                                  cardDetails?.design?.card_id === cardType.id
                                    ? "Selected"
                                    : "Choose this card"}
                                </button>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </fieldset>
                )}
                {fieldSet2 && (
                  <fieldset>
                    <div className="row">
                      <div className="col-md-6">
                        {/* <img src={whiteMain} className="img-fluid" /> */}
                        <div className="digicard">
                          <ul
                            className="list-inline"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li className="list-inline-item">
                              <img
                                src={barcode}
                                className="img-fluid"
                                style={{ width: "75px" }}
                              />
                            </li>
                            <li className="list-inline-item pull-right">
                              <img
                                src={wifi}
                                className="img-fluid"
                                style={{ width: "55px" }}
                              />
                            </li>
                          </ul>
                          <h5 id="printchatbox">
                            {cardDetails?.customize?.name || "Your Name Here"}
                          </h5>
                          <p className="mb-0">
                            <span id="printchatboxx">
                              {cardDetails?.customize?.sub_title ||
                                "Designation"}
                            </span>{" "}
                            -{" "}
                            <span id="printchatboxxx">
                              {cardDetails?.customize?.company_name ||
                                "Company"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* <img src={whiteBack} className="img-fluid" /> */}
                        <div className="digicard logocard">
                          <div className="text-center">
                            {logoUploading ? (
                              "loading"
                            ) : (
                              <img
                                id="output"
                                src={cardDetails.customize.logo || circleLogo}
                                style={{
                                  width: "100px",
                                  borderRadius: "68px",
                                  height: "100px",
                                  border: "3px solid #f5f6f8",
                                  background: "#f5f6f8",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Your Name *</label>
                          <input
                            type="text"
                            name="name"
                            required
                            placeholder="Enter Your Name"
                            className="form-control"
                            value={cardDetails.customize.name}
                            onChange={handleCustomizeCardChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Your Sub-Title *</label>
                          <input
                            type="text"
                            name="sub_title"
                            required
                            placeholder="Enter Your Designation"
                            className="form-control"
                            value={cardDetails.customize.sub_title}
                            onChange={handleCustomizeCardChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Your Company Name *</label>
                          <input
                            type="text"
                            name="company_name"
                            required
                            placeholder="Enter Company Name"
                            className="form-control"
                            value={cardDetails.customize.company_name}
                            onChange={handleCustomizeCardChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <label className="mb-2">Upload Logo</label>
                        </div>
                        <div className="file-sec">
                          <input
                            type="file"
                            id="actual-btn"
                            accept="image/png, image/jpeg, image/jpg, image/webp, image/heic"
                            name="logo"
                            hidden
                            onChange={handleFileInput}
                          />
                          <span id="file-chosen">
                            {logoFileName || "No file selected"}
                          </span>
                          <label for="actual-btn" className="actuals-btn">
                            {logoUploading ? "uploading" : "Choose File"}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <label className="mb-2">Upload profile Picture</label>
                        </div>
                        <div className="file-sec">
                          <input
                            type="file"
                            id="profilePictureBtn"
                            accept="image/png, image/jpeg, image/jpg, image/webp, image/heic"
                            name="logo"
                            hidden
                            onChange={(e) => {
                              handleFileInput(e, "profilePictureBtn");
                            }}
                          />
                          <span id="file-chosen">
                            {profileFileName || "No file selected"}
                          </span>
                          <label for="profilePictureBtn" className="actuals-btn">
                            {profilePicUploading ? "uploading" : "Choose File"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="f1-buttons form-btn"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!paymentStatus && 
                      <button
                        type="button"
                        className="btn btn-previous btns"
                        onClick={() => 
                          !buttonsDisabled ? (setFieldSet1(true),setFieldSet2(false)) : ''
                        }
                      >
                        Back
                      </button>
                      }
                      <button
                        type="submit"
                        className="btn btn-next btns"
                        onClick={() => !buttonsDisabled && handleSaveAndContinue()}
                      >
                        Save and Continue &nbsp;
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </fieldset>
                )}
                {fieldSet3 && (
                  <CustomizeLandingPage
                    fieldSet3={fieldSet3}
                    createCard={createCard}
                    goToTab2={goToTab2}
                    submitHandles={submitHandles}
                    cardId={cardId}
                    handleForm={handleForm}
                    setHandleForm={setHandleForm}
                    handleList={handleList}
                    showPreviewButton={showPreviewButton}
                    setactiveTab={setactiveTab}
                    isCardEditMode={isCardEditMode}
                    inputs={inputs}
                    setInputs={setInputs}
                    handleValues={handleValues}
                    setHandleValues={setHandleValues}
                    paymentStatus={paymentStatus}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
