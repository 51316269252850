import React, { useContext, useEffect, useState } from "react";
import "../style.css";
import { Button, Nav, Tab } from "react-bootstrap";
import background from "../../../Images/background.svg";
import eyeClose from "../../../Images/eye_close.svg";
import eyeOpen from "../../../Images/eye_open.svg";

import logoSvg from "../../../Images/Logo.svg";
import googleSvg from "../../../Images/google.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import request from "./../../../axios";
import { UserContextProvider } from "./../../../Context/UserContext";
import { toast } from "react-toastify";
import { updateLocalStorage } from "./../../../utils";
const Auth = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = process.env.REACT_APP_API_BASE_URL;

  const { user, setUser } = useContext(UserContextProvider);
  const [isUserExist, setIsUserExist] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErr,setPasswordErr] = useState(false);
  const [email,setEmail] = useState(null);
  const [token, setToken] = useState('');
  const [password,setPassword] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    const emailParam = params.get('email');
    if (!tokenParam || !emailParam) {
        // Redirect to /auth if either token or email is missing
        navigate('/auth');
    } else {
        setToken(tokenParam);
        setEmail(emailParam);
    }
  }, [location.search]);
  const handlePassword = (e) =>{
    setPassword(e.target.value);
    setPasswordErr(false);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email,password);
      if(password.length < 8){
        setPasswordErr(true)
      }else{
         request
            .post("/reset_password", {
                "email_id": email,
                "new_password": password,
                "token": token
            })
            .then((response) =>{
            if(response?.status == 200){
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/auth');
                },1500)
            }else{
                toast.error(response?.data?.message);
            }
            })
            .catch((err) => {
            toast.error(err.message || 'Something went wrong asasa');
        })
    }
  };
  return (
    <header>
      <div
        className="dashboard-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="top-assets">
          <ul className="list-inline header-assets">
            <li className="list-inline-item">
              <Link to="/">
                <img src={logoSvg} alt="logo" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="main">
          <div className="d-flex justify-content-center">
            <div className="">
              <p className="mb-0">
                Welcome to <span className="blue">SnapTap</span>
              </p>
            </div>
          </div>
            <div className="reset-mesage">
              <p className="reset-title">Reset Password</p>
              <p>
                Enter your new password below
              </p>
            </div>
          <div>
            <form
            onSubmit={(e) => {
                e.preventDefault();
            }}
            >
            <div className="form-group mb-3">
            <label htmlFor="password" className="mt-2 mb-2">
                Enter your New Password
            </label>
            <div
                style={{
                display: "flex",
                border: "1px solid #ddd",
                borderRadius: "8px",
                }}
            >
                <input
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                required
                min={6}
                className="form-control log-input"
                placeholder="password"
                onChange={handlePassword}
                style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    borderRadius: "8px",
                    border: "none",
                }}
                />
                {!showPassword && (
                <img
                    src={eyeClose}
                    alt=""
                    style={{ paddingRight: "4px", cursor: "pointer" }}
                    onClick={() => {
                    setShowPassword(true);
                    }}
                />
                )}
                {showPassword && (
                <img
                    src={eyeOpen}
                    width={28}
                    alt=""
                    style={{ paddingRight: "4px", cursor: "pointer" }}
                    onClick={() => {
                    setShowPassword(false);
                    }}
                />
                )}
            </div>
                {passwordErr &&
                <p style={{color:"red"}}>
                    Password should be minimum 8 characters.
                </p>
                }
            </div>
                  <div>
                    <Button
                      id="submit"
                      className="btn form-sign-btn mt-4 mb-4 p-2 fw-bold"
                      type="submit"
                      onClick={handleSubmit}
                    >Submit
                    </Button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Auth;
