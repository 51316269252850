import React, { useContext, useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./component/Home";
import Auth from "./component/Auth";
import ForgotPassword from "./component/Auth/ForgotPassword";
import ResetPassword from "./component/Auth/ResetPassword";
import UserPreview from "./component/UserPreview";
import Dashboard from "./component/Dashboard";
import AdminDashboard from "./component/Admin/Dashboard";
import Terms from "./Policies/Terms";
import Privacy from "./Policies/Privacy";
import Refund from "./Policies/Refund";
import Shipping from "./Policies/Shipping";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/:user_name" element={<UserPreview />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/policies/terms-of-service" element={<Terms />} />
          <Route path="/policies/privacy-policy" element={<Privacy />} />
          <Route path="/policies/refund-policy" element={<Refund />} />
          <Route path="/policies/shipping-policy" element={<Shipping />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
