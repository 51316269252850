import React from 'react'
import { Link } from 'react-router-dom'
import background from "../Images/background.svg";
import logoSvg from "../Images/Logo.svg";
import "./style.css";

export default function Refund() {
  return (
    <div className='terms'
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='logo'>
            <Link to={'/'} title=''>
                <img src={logoSvg} alt="logo" />
            </Link>
        </div>
        <div className='content'>
            <h2 className='page-title'>Refund Policy</h2>
            <br/>
            <h4>
            Returns
            </h4>
            <p>
            Our priority policy is to deliver high quality products, unfortunately we can’t offer you a refund or exchange.
            </p>
            <br/>
            <h4>
            Exchanges
            </h4>
            <p>
            We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at contactus@snaptap.me  and send your item to: A-26, Second floor, Tower A, Ithum Techno Park sector 62 Noida.
            </p>
            <p>
            Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
            </p>
            <br/>
            <h4>
            Shipping
            </h4>
            <p>
            To return your product, you should mail your product to: A-26, Second floor, Tower A, Ithum Techno Park sector 62 Noida.
            </p>
            <p>
            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
            </p>
        </div>
    </div>
  )
}
