import React, { useContext, useEffect, useState } from "react";
import call from "../../Images/call.svg";
import customerView from "../../Images/cust-view-bg.png";
import { useNavigate, useParams } from "react-router-dom";
import request from "../../axios";
import { handleNameFix } from "../../utils";
import VCard from "vcard-creator";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const UserPreview = () => {
  let { user_name } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [aboutYourSelf, setaboutYourSelf] = useState("");
  const navigate = useNavigate();
  

  useEffect(() => {
    if (user_name) {
      fetch(`${baseURL}/get_user_info?name=${user_name}`)
        .then((response) => {
          if (!response.ok) {
            navigate('/'); // Redirect to home page
            // throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let userInfo = data?.data;
          if (userInfo) {
            let aboutIndex = userInfo?.handle_values.findIndex(
              (d) => d.handle_name === "about_yourself"
            );

            if (aboutIndex !== -1) {
              setaboutYourSelf(userInfo?.handle_values[aboutIndex]);
            }
            setUserDetails(userInfo);
            sortValues(userInfo?.handle_values);
          }
        })
        .catch((err) => {
          navigate('/');
        });
    }
  }, [user_name]);
  const [sortedHandles,setSortedHandles] = useState([])
  const sortValues = (handleValues) =>{
    const sortedHandleValues = handleValues.sort((a, b) => {
      // Phone numbers first
      if (a.handle_name === "phone_number" && b.handle_name !== "phone_number") return -1;
      if (a.handle_name !== "phone_number" && b.handle_name === "phone_number") return 1;
    
      // Then whatsapp_numbers
      if (a.handle_name === "whatsapp_number" && b.handle_name !== "whatsapp_number") return -1;
      if (a.handle_name !== "whatsapp_number" && b.handle_name === "whatsapp_number") return 1;
    
      // Other values remain in the same order
      return 0;
    });
    setSortedHandles(sortedHandleValues)
  }

  const generateVCFContent = (userDetails) => {
    console.log(userDetails);
    const { name,sub_title,company_name,card_profile_url, handle_values } = userDetails;
    console.log(handle_values);
    // Basic vCard structure
    let vcf = `BEGIN:VCARD\nVERSION:3.0\nFN:${name}\nN:${name}\n`;
    if (company_name && company_name.trim() !== "") {
      vcf += `ORG:${company_name}\n`;
    }
    if (sub_title && sub_title.trim() !== "") {
      vcf += `TITLE:${sub_title}\n`;
    }
    if (card_profile_url && card_profile_url.trim() !== "") {
      vcf += `PHOTO;VALUE=URL:${card_profile_url}\n`;
    }
    let whatsappNumbers = new Set(); // To store unique whatsapp_numbers
  
    handle_values.forEach((handle) => {
      switch (handle.account_id) {
        case "phone_number":
          // Add regular phone number
          vcf += `TEL;TYPE=WORK:${handle.account_value}\n`;
          // If it's also a whatsapp_number
          if (handle.is_whatsapp === 1) {
            whatsappNumbers.add(handle.account_value);
          }
          break;
  
        case "email_id":
          // Add email
          vcf += `EMAIL;TYPE=INTERNET:${handle.account_value}\n`;
          break;
  
        case "location":
          // Add location (assuming it’s a physical address)
          vcf += `ADR;TYPE=WORK:${handle.account_value}\n`;
          break;
  
        case "about_yourself":
          // Add "about yourself" section
          vcf += `NOTE:${handle.account_value}\n`;
          break;
        case "website":
          // Add "about yourself" section
          vcf += `URL:${handle.account_value}\n`;
          break;
  
        default:
          // Treat any other account_id ending with _account as a social media handle
          if (handle.account_id.endsWith("_account")) {
            const platform = handle.account_id.split("_")[0];
            const socialUrl = `${handle.account_value}`;
            
            vcf += `X-SOCIALPROFILE;TYPE=${platform}:${socialUrl}\n`;
            vcf += `URL:${socialUrl}\n`;  // Add this line to save it as a URL
          }
          break;
      }
    });

    // Add all collected whatsapp_numbers
    whatsappNumbers.forEach((number) => {
      vcf += `TEL;TYPE=WHATSAPP:${number}\n`;
    });

    // Add whatsapp_number field separately if it exists
    const whatsappNumber = handle_values.find((handle) => handle.account_id === "whatsapp_number");
    if (whatsappNumber) {
      vcf += `TEL;TYPE=WHATSAPP:${whatsappNumber.account_value}\n`;
    }
  
    vcf += `END:VCARD\n`;
  
    return vcf;
  };


  const downloadVCFFile = () => {
    const vcfContent = generateVCFContent(userDetails);
    const blob = new Blob([vcfContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${userDetails.name || "contact"}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const generateLink = (handle) =>{
    if(handle?.handle_name == "phone_number"){
      return `tel:${handle?.account_value}`;
    }else if(handle?.handle_name == "email_id"){
      return `mailto:${handle?.account_value}`;
    }else if(handle?.handle_name == "whatsapp_number"){
      return `https://wa.me/${handle?.account_value}`;
    }else if(handle?.handle_name == "location"){
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(handle?.account_value)}`;
    }else{
      return handle?.account_value;
    }
  }
  const renderAbout = (value) =>{
    if (value != undefined)
    return value.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  }
  return (
    <div>
      <div class="modal-body">
        <div>
          <div
            class="cust-view-bg"
            style={{ backgroundImage: `url(${customerView})` }}
          >
            {Object.keys(userDetails).length > 0 && (
              <>
                <section class="pt-8" style={{ margin: 0 }}>
                  <div class="container">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-5 col-lg-4 text-center brd-right">
                            <img
                              alt=""
                              src={userDetails.card_profile_url}
                              width={150}
                              height={150}
                              style={{
                                borderRadius: "50%",
                                border: "2px solid black",
                              }}
                            />
                            <p class="circle-usr mb-0">{userDetails.name}</p>
                            <p class="circle-usr-des">
                              {userDetails.sub_title}{userDetails.company_name != "" && ','}{" "}
                              <br/>
                              {userDetails.company_name}
                            </p>
                          </div>
                          <div class="col-md-7 col-lg-8 usr-defini">
                            <p class="pt-2">{renderAbout(aboutYourSelf?.account_value)} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="mt-4 sec-p">
                  <div class="container">
                    <div class="card">
                      <div class="card-body social-rows">
                        <div
                          class="row"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "start",
                          }}
                        >
                          {sortedHandles.map((handle) => {
                            const link = generateLink(handle);
                            return (
                              <>
                                {handle?.handle_name !== "about_yourself" && (
                                  <React.Fragment>
                                  <div class="col-4 col-md-2 col-lg-1 text-center">
                                    <a

                                      href={link}
                                      class="call-hover"
                                      target={handle?.handle_name != "phone_number" && handle?.handle_name != "email_id" ? "_blank" :  "_self"}
                                    >
                                      <img
                                        alt=""
                                        src={handle?.handle_icon || call}
                                        class="call-style"
                                      />
                                      <p class="pt-2">
                                        {handleNameFix(handle?.handle_name)}
                                      </p>
                                    </a>
                                  </div>
                                  {handle?.is_whatsapp == 1 &&
                                  <div class="col-4 col-md-2 col-lg-1 text-center">
                                  <a
                                    href={`https://wa.me/${handle?.account_value}`}
                                    class="call-hover"
                                    target="_blank"
                                  >
                                    <img
                                      alt=""
                                      src={'https://snaptap-logos.s3.ap-south-1.amazonaws.com/icons/whatsapp.svg' || call}
                                      class="call-style"
                                    />
                                    <p class="pt-2">
                                      WhatsApp 
                                    </p>
                                  </a>
                                </div>}
                                  </React.Fragment>
                                )}
                              </>
                            );
                          })}
                          <div class="text-center social-sv-btn">
                            <button
                              class="btn"
                              type="button"
                              onClick={downloadVCFFile}
                            >
                              Save My Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreview;
