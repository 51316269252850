import React, { useEffect, useState } from 'react'

const InputComponent = React.forwardRef((props ,ref) => {
    const label = props.elem.handle_name.replace(/_/g," ").replace(/\b\w/g, (char) => char.toUpperCase());
    const [value,setValue] = useState(props.elem.account_value)
    const handleInputChange = (e) =>{
      setValue(e.target.value);
      props.onChange();
    }
    useEffect(() => {
      setValue(props.elem.account_value);
    },[props.elem.account_value]);
  return (
    props.elem.handle_name !== "about_yourself"
    ? 
    <React.Fragment>
        <label className='input-label-dlt'>
        {`${label} ${
                props.index <= 1 ? "*" : ""
        }`}
        {props.index > 1 && 
          <div className='remove-input' title='Remove' onClick={() => props.removeInput(props.index)}>
            <i class="fa fa-trash" aria-hidden="true"></i>
          </div>
        }
        </label>
        <input type={(props.elem.handle_name == "phone_number" || props.elem.handle_name == "whatsapp_number") ? "tel" : props.elem.handle_name == "email_id" ? "email" : "text"}
        name={props.elem.handle_name} 
        placeholder={props.elem.handle_placeholder} 
        value={value} 
        pattern={props.elem.handle_validation}
        onChange={handleInputChange}
        minLength={(props.elem.handle_name === "phone_number" || props.elem.handle_name == "whatsapp_number") ? 10 : ""}
        maxLength={(props.elem.handle_name === "phone_number" || props.elem.handle_name == "whatsapp_number") ? 10 : ""}
        class="form-control"
        ref={ref}/>
    </React.Fragment>
    :
    <React.Fragment>
      <label>About Yourself *</label>
              <textarea
                class="form-control"
                name={props.elem.handle_name} 
                value={value}
                placeholder={props.elem.handle_placeholder} 
                maxLength={200}
                onChange={handleInputChange}
                ref={ref}
            ></textarea>
    </React.Fragment>
  )
})

export default InputComponent;
