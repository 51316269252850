// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms{
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
}
.content{
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
}
.page-title{
    font-size: 20px;
    color: rgba(0,0,0,0.8);
    text-align: center;
}
p{
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    margin-bottom: 8px;
}
h4{
    font-size: 16px;
    color: rgba(0,0,0,0.8);
}`, "",{"version":3,"sources":["webpack://./src/Policies/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,4BAA4B;IAC5B,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".terms{\n    padding: 20px;\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-attachment: fixed;\n    min-height: 100vh;\n}\n.content{\n    margin-top: 20px;\n    background: #fff;\n    padding: 20px;\n    border-radius: 10px;\n}\n.page-title{\n    font-size: 20px;\n    color: rgba(0,0,0,0.8);\n    text-align: center;\n}\np{\n    font-size: 14px;\n    color: rgba(0,0,0,0.6);\n    margin-bottom: 8px;\n}\nh4{\n    font-size: 16px;\n    color: rgba(0,0,0,0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
