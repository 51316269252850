import React, { useContext, useEffect, useState } from "react";
import "../style.css";
import { Button, Nav, Tab } from "react-bootstrap";
import background from "../../../Images/background.svg";
import eyeClose from "../../../Images/eye_close.svg";
import eyeOpen from "../../../Images/eye_open.svg";

import logoSvg from "../../../Images/Logo.svg";
import googleSvg from "../../../Images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import request from "./../../../axios";
import { UserContextProvider } from "./../../../Context/UserContext";
import { toast } from "react-toastify";
import { updateLocalStorage } from "./../../../utils";
const Auth = (props) => {
  const navigate = useNavigate();
  const redirectUrl = process.env.REACT_APP_API_BASE_URL;

  const { user, setUser } = useContext(UserContextProvider);
  const [isUserExist, setIsUserExist] = useState(null);
  const [email,setEmail] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
     request
        .post("/forgot_password", {email})
        .then((response) =>{
          if(response?.status == 200){
            toast.success(response.data.message);
            console.log('sucess');
          }else{
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err.message || 'Something went wrong asasa');
        })
  };
  return (
    <header>
      <div
        className="dashboard-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="top-assets">
          <ul className="list-inline header-assets">
            <li className="list-inline-item">
              <Link to="/">
                <img src={logoSvg} alt="logo" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="main">
          <div className="d-flex justify-content-center">
            <div className="">
              <p className="mb-0">
                Welcome to <span className="blue">SnapTap</span>
              </p>
            </div>
          </div>
            <div className="reset-mesage">
              <p className="reset-title">Forgot Password</p>
              <p>
                In order to reset password please enter your email address below , we'll send a link to reset your password.
              </p>
            </div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="mt-2 mb-2">
                      Enter your Email
                    </label>
                    <input
                      type="email"
                      required
                      name="email"
                      className="form-control log-input"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      id="submit"
                      className="btn form-sign-btn mt-4 mb-4 p-2 fw-bold"
                      type="submit"
                      onClick={handleSubmit}
                    >Submit
                    </Button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Auth;
