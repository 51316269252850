import { useContext, useEffect, useState } from "react";
import request from "../../../../axios";
import { UserContextProvider } from "../../../../Context/UserContext";
import { toast } from "react-toastify";
import { CardDetailsProvider } from "../../../../Context/CardDetailsContext";

const SelectAddressModal = ({
  showModal,
  closeModal,
  cardId,
  showPreviewButton,
  setOnAddressSaved,
}) => {
  const { user, setUser } = useContext(UserContextProvider);
  const { cardDetails, setCardDetails } = useContext(CardDetailsProvider);

  const [addresses, setAddresses] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [fieldDetails, setFieldDetails] = useState({
    name: "",
    phone_number: user.phone_number != undefined && user.phone_number != null ? user.phone_number : "",
    flat: "",
    area: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  useEffect(() => {
    request
      .get(`/list_addresses?user_id=${user.id}`)
      .then((res) => {
        console.log(res.data.data.list);
        if (res && res.data && res.data.data && res.data.data.list) {
          setAddresses(res.data.data.list);
          if (res.data.data.list.length > 0) {
            setShowNewAddress(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showModal]);

  const showAddNewAddress = () => {
    setShowNewAddress(true);
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && `${value}`.length > 10) {
      return;
    }
    setFieldDetails((fields) => {
      return {
        ...fields,
        [name]: value,
      };
    });
  };

  const updatedAddreddInCard = (addressId) => {
    const data = {
      ...cardDetails.customize,
      card_id: cardDetails.design.card_id,
      user_id: user.id,
      address_id: addressId || "",
      id: cardId,
    };

    request
      .post("/update_card", data)
      .then((res) => {
        setOnAddressSaved(true);
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddressSave = (e) => {
    e.preventDefault();
    let data = { ...fieldDetails };
    let path = "/create_address";
    data.user_id = user.id;
    data.country = "India";

    if (data.name && data.pincode.length !== 6) {
      toast.error("Pincode must be of 6 digit");
      return;
    }

    if (
      data.name &&
      data.phone_number &&
      data.city &&
      data.state &&
      data.pincode &&
      data.country
    ) {
      request
        .post(path, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Address added successfully");
            setAddressId(res?.data?.id);

            // call updatedCard here
            updatedAddreddInCard(res?.data?.id);
          }
          setOnAddressSaved(true);
          closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchPincodeInfo = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      let data = await response.json();
      data = data[0];

      if (data["Status"] === "Success") {
        const state = data.PostOffice[0]?.State || "";
        const city = data.PostOffice[0]?.Division || "";

        setFieldDetails((fields) => {
          return {
            ...fields,
            pincode: pincode,
            state,
            city,
          };
        });
      } else {
        toast.error("Invalid pincode");
      }
    } catch (error) {
      toast.error("Error fetching pincode information");
    }
  };

  const onPinCodeChange = (e) => {
    let value = e.target.value;
    setFieldDetails((fields) => {
      return {
        ...fields,
        pincode: value,
      };
    });

    if (value.length === 6) {
      fetchPincodeInfo(value);
    } else {
      handleValueChange(e);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="addressAdd">
          <div className="modelContent model-content">
            <div className="modelContent1" style={{ width: "40%" }}>
              <div class="modal-header">
                <h5 class="modal-title">
                  {addresses.length > 0 ? "Select Address" : "Add new Address"}
                </h5>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAddressId("");
                    closeModal();
                  }}
                >
                  <span>X</span>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div class="modal-body">
                  <div id="appenddiv">
                    <div class="row add-para">
                      {addresses.length <= 0 && (
                        <>
                          {!showNewAddress && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "bolder",
                                  fontSize: "40px",
                                }}
                              >
                                No Address{" "}
                              </div>

                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={showAddNewAddress}
                              >
                                Add new address
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      {showNewAddress && (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="name">Name</label>
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    pattern="[A-Za-z ]+"
                                    minlength={6}
                                    required
                                    class="form-control input"
                                    name="name"
                                    value={fieldDetails.name}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="phone_number">
                                    Mobile Number
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Mobile Number"
                                    minLength={10}
                                    maxLength={10}
                                    required
                                    class="form-control input"
                                    name="phone_number"
                                    value={
                                      fieldDetails.phone_number ||
                                      user?.phone_number ||
                                      ""
                                    }
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="flat">
                                    Flat, House no., Building
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Flat, House no., Building"
                                    class="form-control input"
                                    name="flat"
                                    value={fieldDetails.flat}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="area">
                                    Area, Street, Sector
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Area, Street, Sector"
                                    class="form-control input"
                                    name="area"
                                    value={fieldDetails.area}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="landmark">Landmark</label>
                                  <input
                                    type="text"
                                    placeholder="Landmark"
                                    class="form-control input"
                                    name="landmark"
                                    value={fieldDetails.landmark}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="city">Town/City</label>
                                  <input
                                    type="text"
                                    placeholder="Town/City"
                                    class="form-control input"
                                    required
                                    name="city"
                                    value={fieldDetails.city}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="state">State</label>
                                  <input
                                    type="text"
                                    placeholder="State"
                                    required
                                    class="form-control input"
                                    name="state"
                                    value={fieldDetails.state}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label htmlFor="pincode">Pincode</label>
                                  <input
                                    type="number"
                                    placeholder="Pincode"
                                    min="100000"
                                    max="999999"
                                    required
                                    className="form-control input"
                                    name="pincode"
                                    value={fieldDetails.pincode}
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer text-center">
                            <div class="popup-btn">
                              <button
                                class="btn theme-btn"
                                type="submit"
                                onClick={handleAddressSave}
                              >
                                Save Address
                              </button>
                            </div>
                          </div>
                        </form>
                      )}

                      {addresses.length > 0 &&
                        addresses.map((address, index) => {
                          return (
                            <div class="col-md-6">
                              <div class="cust-scroller">
                                <p
                                  id="edit"
                                  onClick={() => {
                                    setAddressId(address.id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    border:
                                      addressId === address.id
                                        ? "3px solid #1458e9"
                                        : "1px solid #1458e9",
                                  }}
                                >
                                  <b>
                                    <span>{address.name}</span>
                                    <br />
                                    <span>{address.flat}</span>
                                    <br />
                                    <span>
                                      {address.area} {address.landmark}
                                    </span>
                                    <br />
                                    <span>
                                      {address.city} {address.state}{" "}
                                      {address.pincode}
                                    </span>
                                    <br />
                                    <span>{address.phone_number}</span>
                                  </b>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {addresses.length > 0 && (
                  <div
                    class="modal-footer text-center"
                    style={{
                      margin: "0 auto",
                      borderTop: "none !important",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => {
                        updatedAddreddInCard(addressId);
                      }}
                      style={{ width: "100px" }}
                      disabled={addressId ? false : true}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectAddressModal;
