import React from 'react'
import { Link } from 'react-router-dom'
import background from "../Images/background.svg";
import logoSvg from "../Images/Logo.svg";
import "./style.css";

export default function Shipping() {
  return (
    <div className='terms'
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='logo'>
            <Link to={'/'} title=''>
                <img src={logoSvg} alt="logo" />
            </Link>
        </div>
        <div className='content'>
            <h2 className='page-title'>Shipping policy</h2>
            <br/>
            <p>
            All orders are processed within 3 to 5 business days (excluding weekends and holidays) after receiving your order confirmation email. You will receive another notification when your order has shipped. 
            </p>
            <br/>
            <h4>
            Domestic Shipping Rates and Estimates
            </h4>
            <p>
            Shipping charges for your order will be calculated and displayed at checkout. We offer Rs 99 flat rate shipping to pan India. 
            </p>
            <br/>
            <h4>
            In-store pickup
            </h4>
            <p>
            You can skip the shipping fees with free local pickup at Sec 62 Noida. After placing your order and selecting local pickup at checkout, your order will be prepared and ready for pick up within 2 to 4 business days. We will send you an email when your order is ready along with instructions. 
            </p>
            <p>
            Our in-store pickup hours are 10 AM - 6 PM on all days except Sunday. Please have your order confirmation email with you when you come.
            </p>
            <br/>
            <h4>
            International Shipping
            </h4>
            <p>
            We do offer international shipping as of now.
            </p>
        </div>
    </div>
  )
}
