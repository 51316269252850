/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useRef, useState } from "react";
import Logo from "../../Images/Logo.svg";
import backgroundImage from "../../Images/background.svg";
import logout from "../../Images/logout.svg";
import dashboardList from "./list";
import Card from "./Card";
import Order from "./Order";
import Address from "./Address";
import AccountDetails from "./AccountDetails";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserContextProvider } from "../../Context/UserContext";
import { toast } from "react-toastify";
import request from "../../axios";
import { updateLocalStorage } from "../../utils";
import AWS from "aws-sdk";
import { Button } from "react-bootstrap";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const rootS3_BUCKET = S3_BUCKET.split("/")[0];
const REGION = process.env.REACT_APP_REGION;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_accessKeyId,
  secretAccessKey: process.env.REACT_APP_secretAccessKey,
});
const myBucket = new AWS.S3({
  params: { Bucket: rootS3_BUCKET },
  region: REGION,
});

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContextProvider);
  const [activeTab, setactiveTab] = useState("myCards");
  const [searchParams, setSearchParams] = useSearchParams();
  const [logoUploading, setLogoUploading] = useState(false);
  const [profilePic,setProfilePic] = useState('');
  const uploadRef = useRef(null);
  const beingUploaded = () =>{
    document.body.style.pointerEvents = 'none';
  }
  const fileUploaded = () =>{
    document.body.style.pointerEvents = 'auto';
  }
  useEffect(() => {
    const isCallback = searchParams.get("callback");
    const callbackuserId = searchParams.get("userId");
    let localStorageDetail = localStorage.getItem("snaptapId");

    if (isCallback && callbackuserId) {
      request
        .get(`/get_user?id=${atob(callbackuserId)}`)
        .then((res) => {
          if (res?.data?.user) {
            if (res?.data?.user?.is_admin) {
              navigate("/admin/dashboard");
            }
            updateLocalStorage(atob(callbackuserId));
            setUser({ ...res.data.user, isOauth: true });
            setProfilePic(res.data.user.profile_pic_url);
            setSearchParams("");
          }
        })
        .catch((err) => {
          navigate("/auth");
        });
    } else {
      if (localStorageDetail) {
        localStorageDetail = localStorageDetail.split(" ");
        let currentTime = Date.now();
        let userId = localStorageDetail[0];
        let storedTime = localStorageDetail[1];
        storedTime = atob(storedTime);
        if (storedTime - currentTime <= 0) {
          localStorage.removeItem("snaptapId");
          // navigate("/auth");
          // window.location.href = "/auth";
          navigate("/auth");
        } else {
          request
            .get(`/get_user?id=${atob(userId)}`)
            .then((res) => {
              let user = res?.data?.user;
              if (user) {
                setUser(res.data.user);

                if (user?.is_admin) {
                  navigate("/admin/dashboard");
                }
              }
            })
            .catch((err) => {
              navigate("/auth");
            });
        }
      } else {
        navigate("/auth");
      }
    }
  }, []);

  const showRightSection = () => {
    switch (activeTab) {
      case "myCards":
        return <Card user={user} setactiveTab={setactiveTab} />;
      case "myOrders":
        return <Order />;
      case "myAddress":
        return <Address />;
      case "accountDetails":
        return <AccountDetails />;
      default:
        break;
    }
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  const handleLogOut = () => {
    // conditions
    localStorage.removeItem("snaptapId");
    setUser({});
    toast.success("Log out successfully");
    navigate("/auth");
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    uploadFile(file); // Call the uploadFile function directly
  };

  const uploadFile = (file) => {
    setLogoUploading(true);
    beingUploaded();
    let folderName = "snaptap_profile_pic";
    return new Promise((resolve, reject) => {
      console.log("Uploading file:", file.name);
      const fileExtension = file.name.split('.').pop();
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: rootS3_BUCKET,
        Key: `${folderName}/${user.id}.${fileExtension}`,
      };

      myBucket.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
          setLogoUploading(false);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data);
          // Get the URL of the uploaded file
          try {
            await callGetMediaUrl(`${user.id}.${fileExtension}`,folderName);
          } catch (error) {
            console.error("Error calling get_media_url API:", error);
            setLogoUploading(false);
            // Handle error
          }
          resolve(data);
          fileUploaded();
        }
      });
    });
  };

  const callGetMediaUrl = async (file,folder) => {
    console.log(file, "file");
    try {
      const response = await request.get("/get_media_url", {
        params: { file_name: file ,folder_name : folder },
      });
      let url = response?.data?.url;

      if (url) {
        url = url.split("?")[0];
      }
      
      setProfilePic(url)
      console.log("URL ", url, uploadRef.current.value);
      uploadRef.current.value = "";

      // update api
      
      let data = {
        user_id: user.id,
        profile_pic_url: url,
      };

      request
        .post("update_user", data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });

      setUser((data) => {
        return {
          ...data,
          profile_pic_url: url,
        };
      });
      setLogoUploading(false);
    } catch (error) {
      console.error("Error calling get_media_url API:", error);
      setLogoUploading(false);

      // Handle error
    }
  };

  return (
    <>
      {user?.id && (
        <header style={{ overflowX: "hidden" }}>
          <div
            className="dashboard-bg"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div className="top-assets header-shadow">
              <ul className="list-inline header-assets mob-menu-top">
                <li className="list-inline-item sidenv">
                  <span
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: "#1458e9",
                    }}
                    className="sidenv"
                    onClick={openNav}
                  >
                    &#9776;
                  </span>
                </li>
                <li className="list-inline-item">
                  <Link to="/dashboard">
                    <img src={Logo} alt="" />
                  </Link>
                </li>
                <li className="list-inline-item float-right">
                  <div onClick={handleLogOut}>
                    <img src={logout} class="logout-btn" />
                    <button
                      type="button"
                      className="btn form-control theme-btn desk-logout-btn"
                    >
                      Log Out
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="container-fluid">
              <div className="dashboard">
                <div className="row wiz-bx">
                  <div className="col-md-3">
                    <div id="mySidenav" className="sidenav">
                      <div className="menubars">
                        <div className="text-center user-pic">
                          <span className="closebtn" onClick={closeNav}>
                            &times;
                          </span>
                          <img src={profilePic != null && profilePic != '' ? profilePic : user.profile_pic_url} alt="" />
                          <div>
                            <input
                              type="file"
                              hidden
                              accept="image/png, image/jpeg, image/jpg, image/webp, image/heic"
                              ref={uploadRef}
                              onChange={handleFileInput}
                            ></input>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                uploadRef.current.click();
                              }}
                              disabled={logoUploading}
                            >
                              {logoUploading ? "uploading" : "Upload"}
                            </Button>
                          </div>
                          <h4>{user.name || `--------`}</h4>
                          <p>{user.email}</p>
                        </div>
                        <div
                          className="nav mob-menu flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {dashboardList.map((list) => {
                            return (
                              <>
                                <a
                                  className={`nav-link tab1 ${
                                    activeTab === list.name && "active"
                                  }`}
                                  id="v-pills-home-tab"
                                  data-toggle="pill"
                                  href={`#${list.name}`}
                                  role="tab"
                                  aria-controls="v-pills-home"
                                  aria-selected="true"
                                  onClick={() => {
                                    setactiveTab(list.name);
                                    closeNav();
                                  }}
                                >
                                  <img src={list.greyIcon} className="grey" />
                                  <img
                                    src={list.blueIcon}
                                    className="blue"
                                  />{" "}
                                  &nbsp;&nbsp;&nbsp; {list.label}
                                </a>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="menubars desk-hide">
                      <div className="text-center user-pic">
                        <img src={user.profile_pic_url} />
                        <div>
                          <input
                            type="file"
                            hidden
                            accept="image/png, image/jpeg, image/jpg, image/webp, image/heic"
                            ref={uploadRef}
                            onChange={handleFileInput}
                          ></input>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              uploadRef.current.click();
                            }}
                            disabled={logoUploading}
                          >
                            {logoUploading ? "uploading" : "Upload"}
                          </Button>
                        </div>
                        <h4>{user.name || `--------`}</h4>
                        <p>{user.email}</p>
                      </div>
                      <div
                        className="nav mob-menu flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {dashboardList.map((list) => {
                          return (
                            <>
                              <a
                                className={`nav-link tab1 ${
                                  activeTab === list.name && "active"
                                }`}
                                id="v-pills-home-tab"
                                data-toggle="pill"
                                href={`#${list.name}`}
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  setactiveTab(list.name);
                                }}
                              >
                                <img src={list.greyIcon} className="grey" />
                                <img
                                  src={list.blueIcon}
                                  className="blue"
                                />{" "}
                                &nbsp;&nbsp;&nbsp; {list.label}
                              </a>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {showRightSection()}
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Dashboard;
