import React from "react";
import Facebook from "../../../Images/Facebook footer.svg";
import Google from "../../../Images/Google Footer.svg";
import Instagram from "../../../Images/instagram footer.svg";
import Apple from "../../../Images/Apple footer.svg";
import "../style.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section class="footer-section mb-0 mt-2">
      <div class="container">
        <ul class="list-inline footer-list">
          <li class="list-inline-item">
            <a href="#about_us">About Us</a>
          </li>
          <li class="list-inline-item">
            <a href="#faq">Why Choose Us?</a>
          </li>
          <li class="list-inline-item">
            <a href="#products">Products</a>
          </li>
          <li class="list-inline-item">
            <a href="#threeWays">Resources</a>
          </li>
          <li class="list-inline-item">
            <a href="">Company</a>
          </li>
        </ul>
        <ul class="list-inline footer-list privacy-links">
          <li class="list-inline-item">
            <Link to={'/policies/terms-of-service'}>
            Terms of service
            </Link>
          </li>
          <li class="list-inline-item">
            <Link to={'/policies/privacy-policy'}>
            Privacy Policy
            </Link>
          </li>
          <li class="list-inline-item">
            <Link to={'/policies/refund-policy'}>
            Refund Policy
            </Link>
          </li>
          <li class="list-inline-item">
            <Link to={'/policies/shipping-policy'}>
            Shipping Policy
            </Link>
          </li>
        </ul>
        <div class="text-center">
          <ul
            class="list-inline social-icn pt-4"
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            <li class="list-inline-item">
              <img src={Facebook} alt="facebook" />
            </li>
            <li class="list-inline-item">
              <img src={Google} alt="google" />
            </li>

            <li class="list-inline-item">
              <img src={Apple} alt="apple" />
            </li>
            <li class="list-inline-item">
              <Link to="https://www.instagram.com/snaptap.me/" target="_blank">
                <img src={Instagram} alt="instagram" />
              </Link>
            </li>
          </ul>
          <p>© 2023 SnapTap. All Rights Reserved.</p>
        </div>
      </div>
    </section>
  );
}

export default Footer;
